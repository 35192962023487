import React from "react";
import "./Sea.css";
import bannerstarsimg from "../Images/Group 1000008511.svg";
import bannerlisvg from "../Images/Group 1000008655.svg";
import seaboatimg from "../Images/image 100.jpg";
import Section from "./Section";
import { HashLink } from "react-router-hash-link";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";


function Sea() {
  return (
    <div>
      <Helmet>
        <title>Sea Cargo </title>
        <meta name="description" content="Comprehensive Cargo Solutions: Explore Our Range of Services Tailored for Your Shipping Needs. From Express Deliveries to Specialized Handling, We've Got You Covered. Experience Seamless Transfers from the UK to Pakistan with Our Dedicated Services." />
      </Helmet>
      <div className="container-fluid sea-banner-div1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 for-mobile-about-banner-col">
              <div>
                {/* <Fade bottom distance="1%"> */}
                <div className="home-banner-stars-div1">
                  <img src={bannerstarsimg} />
                  <p>Loved by 100,000+ Happy cusmtomers</p>
                </div>
                {/* </Fade> */}
                <div className="d-md-block d-none">
                  <Fade bottom distance="1%">
                    <h1 className="home-banner-text1 ">
                      Send Cargo
                      <span> By SEA</span>
                    </h1>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p className="sea-banner-para1">
                      {" "}
                      Choose sea cargo for reliable and cost-effective
                      transportation worldwide. Trust us to handle your
                      shipments securely and deliver them on time, every time.{" "}
                    </p>
                  </Fade>
                  <Fade bottom distance="1%">
                    <ul className="home-banner-ul-div1">
                      <li>
                        <img src={bannerlisvg} alt="" />
                        <p>Hassle Free </p>
                        <img src={bannerlisvg} className="ml-4" alt="" />
                        <p>On Time</p>
                      </li>

                      <li>
                        <img src={bannerlisvg} alt="" />
                        <p>Door to Door Service</p>
                      </li>
                    </ul>
                  </Fade>

                  <div>
                    <Fade bottom distance="1%">
                      <HashLink
                        to="#contact-form"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                      >
                        <div className="banner-quote-btn1">
                          Get a Quote{" "}
                          <svg
                            width="15"
                            height="13"
                            viewBox="0 0 15 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.80958 13L7.77052 11.9744L12.0482 7.69673H0.969379V6.21236H12.0482L7.77052 1.94815L8.80958 0.909091L14.855 6.95455L8.80958 13Z"
                              fill="#01070C"
                            />
                          </svg>
                        </div>
                      </HashLink>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>

            <Fade bottom distance="1%">
              <div className="col-lg-6 sea-banner-card-maindiv1">



                <div>
                  <div className="sea-banner-card-div1">
                    <div className="sea-banner-card-svgdiv1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49"
                        height="53"
                        viewBox="0 0 49 53"
                        fill="none"
                      >
                        <path
                          d="M24.3926 6.84783V1"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M47.7826 34.1355C44.2252 46.5621 27.7538 50.8992 24.8299 51.5814C24.5421 51.6547 24.2405 51.6547 23.9527 51.5814C21.0288 50.8992 4.55743 46.5621 1 34.1355V25.8023C0.999966 25.3918 1.1295 24.9918 1.37014 24.6593C1.61077 24.3269 1.95023 24.0787 2.34013 23.9505L23.7822 16.7869C24.1791 16.6648 24.6035 16.6648 25.0004 16.7869L46.4425 23.9505C46.8324 24.0787 47.1718 24.3269 47.4125 24.6593C47.6531 24.9918 47.7826 25.3918 47.7826 25.8023V34.1355Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M24.8337 45.2646C22.5204 44.7318 9.48842 41.3459 6.67383 31.6445"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.67383 9.51756V21.9943L24.2662 15.7519L41.8586 21.9943V9.51756C41.8586 7.94593 40.5846 6.67188 39.0129 6.67188H9.51951C7.94789 6.67188 6.67383 7.94593 6.67383 9.51756Z"
                          fill="#F7B53C"
                          stroke="black"
                          stroke-width="2"
                        />
                        <path
                          d="M24.3926 36.0867V16.6914"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M29.375 35.0504V27.1055"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.2148 16.8898V11.2148"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M46.4004 19.1603V8.94531"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <h4 className="d-md-none">Send sea Cargo</h4>
                    </div>
                    <h2>
                      <div></div> FROM £1.45
                      <span className="d-md-block d-none ml-2"> [ Per Kg ]</span>{" "}
                      <span className="d-md-none d-block">/KG</span>
                    </h2>
                    <ul>
                      <li>
                        Minimum<span> 25 Kg</span>
                      </li>
                      <li>
                        Electric Items <span> £3.50 Per Kg</span>
                      </li>
                    </ul>

                    <div>
                      <Fade bottom distance="1%">
                        <HashLink
                          to="#contact-form"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={600}
                        >
                          {" "}
                          <div className="sea-btn1">Get a Quote</div>
                        </HashLink>
                      </Fade>
                    </div>
                  </div>
                </div>


              </div>

            </Fade>
          </div>
        </div>
      </div>

      <div className="container-fluid sea-services-div12">
        <div className="container">
          <Fade bottom distance="1%">
            <h2 className="sea-services-text1">
              SEA FREIGHT <span> SERVICE</span>
            </h2>
          </Fade>
          <Fade bottom distance="1%">
            <p className="sea-services-text2">
              "With a renowned reputation in industrial logistics, our sea
              freight services offer unmatched cost-effectiveness and safety. If
              cost is crucial and time is flexible, our sea freight services are
              your ideal choice. We provide comprehensive cargo shipment
              options, including land, sea, air, and rail, prioritizing your
              needs and ensuring zero damage during transit."
            </p>
          </Fade>

          <div className="sea-services-text-div1">
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Cost-effective transportation
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Suitable for large volume shipments
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Environmentally friendly option
              </p>
            </Fade>
          </div>
          <div className="sea-services-text-div1">
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Ability to transport oversized items
              </p>
            </Fade>
            <Fade bottom distance="1%">
              <p>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.36036 13L7.3213 11.9744L11.599 7.69673H0.520161V6.21236H11.599L7.3213 1.94815L8.36036 0.909091L14.4058 6.95455L8.36036 13Z"
                    fill="#F7B53C"
                  />
                </svg>{" "}
                Lower risk of theft or damage compared to other modes of
                transport
              </p>
            </Fade>
          </div>
          <Fade bottom distance="1%">
            <img src={seaboatimg} className="sea-boat-img12 img-fluid" alt="" />
          </Fade>
          <Fade bottom distance="1%">
            <p className="sea-boat-para1">
              "At our core, customer satisfaction drives our operations. With
              swift communication, transparency, and a dedicated team of
              international sea freight experts, we ensure your logistics needs
              are met with utmost care and efficiency."
            </p>
          </Fade>
        </div>
      </div>

      <Section></Section>
    </div>
  );
}

export default Sea;
