import React from "react";
import "./Home.css";
import bannerstarsimg from "../Images/Group 1000008511.svg";
import bannerlisvg from "../Images/Group 1000008655.svg";
import solutionboatimg from "../Images/boat 3.png";
import solutionplanimg from "../Images/plane 2.jpg";
import cardimg1 from "../Images/Group 1000008659.svg";
import cardimg2 from "../Images/Group 1000008660.svg";
import cardimg3 from "../Images/Group 1000008517.svg";
import cardimg4 from "../Images/Group 1000008658.svg";
import itemimg1 from "../Images/Rectangle 41070.png";
import itemimg2 from "../Images/Rectangle 41071.png";
import itemimg3 from "../Images/Rectangle 41072.png";
import itemimg4 from "../Images/Rectangle 41073.png";
import itemimg5 from "../Images/Rectangle 41074.png";
import itemimg6 from "../Images/Rectangle 41075.png";
import itemimg7 from "../Images/Rectangle 41076.png";
import itemimg8 from "../Images/Rectangle 41077.png";
import homesvg1 from "../Images/Group 1000008556.svg";
import homesvg2 from "../Images/Group 1000008555.svg";
import homesvg3 from "../Images/Group 1000008557.svg";
import homesvg4 from "../Images/Group 1000008560.svg";
import homesvg5 from "../Images/Group 1000008559.svg";
import homesvg6 from "../Images/Group 1000008558.svg";
import footerimg from "../Images/image 2172.jpg";
import mobileboat from '../Images/boat 4.png'
import mobileair from '../Images/plane 2.png'
import mobileicons1 from '../Images/Layer_1.svg'
import mobileicons2 from '../Images/Group 1000008552.svg'
import mobileicons3 from '../Images/Group 1000008573.svg'
import { HashLink } from "react-router-hash-link";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import ideabox from '../Images/image 2170.png'
import { Link } from 'react-router-dom'



import Section from "./Section";

function Home() {
  return (
    <div>

      <Helmet>
        <title>PK Cargo </title>
        <meta name="description" content="Reliable Cargo Shipping Service: Your Trusted Partner for UK to Pakistan Transfers. Efficient, Secure, and Affordable Solutions for Seamless Cargo Transport. Book Now!" />
      </Helmet>

      <div className='container-fluid home-mobile-top-div d-md-none d-block pl-0 pr-0'>
        <div className='container'>
          <div className='row home-mobile-top-rowdiv'>
            <div className='col-6 home-mobile-top-col1-div'>
              <Link to='/sea-cargo'>
                <Fade bottom distance="1%">
                  <div className='home-mobile-top-text-div'>
                    <div>
                      <h2>Starting from</h2>
                      <h3>£ 1.45 <span>/KG</span></h3>
                    </div>
                    <div className='home-mobile-top-img-div'>
                      <img src={mobileboat} className="img-fluid" />
                    </div>
                  </div>
                </Fade>
              </Link>
            </div>




            <div className='col-6 home-mobile-top-col2-div'>
              <Link to='/air-cargo'>
                <Fade bottom distance="1%">
                  <div className='home-mobile-top-text-div'>
                    <div>
                      <h2>Starting from</h2>
                      <h3>£ 9.00 <span>/KG</span></h3>
                    </div>
                    <div className='home-mobile-top-img-div'>
                      <img src={mobileair} className="img-fluid" />
                    </div>
                  </div>
                </Fade>
              </Link>
            </div>
          </div>
        </div>
      </div>


















      <div className="container-fluid home-banner-div1">
        <div className="container moblie-home-banner-div1">
          <div>
            <div className="home-banner-stars-div1">
              <img src={bannerstarsimg} />
              <p>Loved by 100,000+ Happy cusmtomers</p>
            </div>
            <div className="for-mobile-responsive-homebanner">
              <h1 className="home-banner-text1">
                Send Cargo to<br></br>
                <span>Pakistan and Kashmir</span>
              </h1>
              <ul className="home-banner-ul-div1 d-md-block d-none">
                <li>
                  <img src={bannerlisvg} alt="" />
                  <p>No Custom, No Duty</p>
                </li>
                <li>
                  <img src={bannerlisvg} alt="" />
                  <p>No Handling Charges</p>
                </li>
                <li>
                  <img src={bannerlisvg} alt="" />
                  <p>Door to Door Service</p>
                </li>
              </ul>

              <div>
                <HashLink
                  to="#contact-form"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={600}
                >
                  <div className="banner-quote-btn1">
                    Get a Quote{" "}
                    <svg
                      width="15"
                      height="13"
                      viewBox="0 0 15 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.80958 13L7.77052 11.9744L12.0482 7.69673H0.969379V6.21236H12.0482L7.77052 1.94815L8.80958 0.909091L14.855 6.95455L8.80958 13Z"
                        fill="#01070C"
                      />
                    </svg>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>












      <div className='container-fluid mobile-home-icon-div1 d-md-none'>
        <div className='container'>
          <div className='row'>
            <div className="col-4 mobile-home-icon-div2">
              <div>
                <div>
                  <Fade bottom distance="1%">
                    <img src={mobileicons1} alt="" />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <p >No Custom <br />
                    No Duty</p>
                </Fade>
              </div>
            </div>


            <div className="col-4 mobile-home-icon-div2">
              <div>
                <div>
                  <Fade bottom distance="1%">
                    <img src={mobileicons2} alt="" />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <p >No Handling<br />
                    Charges</p>
                </Fade>
              </div>
            </div>

            <div className="col-4 mobile-home-icon-div2">
              <div>
                <div>
                  <Fade bottom distance="1%">
                    <img src={mobileicons3} alt="" />
                  </Fade>
                </div>
                <Fade bottom distance="1%">
                  <p >Door to Door <br />
                    Service</p>
                </Fade>
              </div>
            </div>

          </div>
        </div>
      </div>















      {/* // ########### Section No 2 ############ */}
      <div className="container-fluid home-solution-div1 d-md-block d-none">
        <div className="container">
          <Fade bottom distance="1%">
            <h2 className="home-solution-text1 d-md-block d-none">OUR SOLUTIONS</h2>
          </Fade>
          <Fade bottom distance="1%">
            <p className="home-solution-text2 d-md-block d-none">
              A comprehensive range of services catering <br />
              to all your logistic needs.
            </p>
          </Fade>
          <div className="row">
            <div className="col-lg-6  ">
              <Fade bottom distance="1%">
                <div className="home-solution-col-div1">
                  <Fade bottom distance="1%">
                    <img src={solutionboatimg} className="img-fluid" alt="" />
                  </Fade>
                  <Fade bottom distance="1%">
                    <h2>Starting from</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <h1>£ 1.45</h1>
                  </Fade>
                  <div>
                    <Fade bottom distance="1%">
                      <HashLink
                        to="#contact-form"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                      >
                        <div className="home-solution-btn1">Get a Quote</div>
                      </HashLink>
                    </Fade>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-6 ">
              <Fade bottom distance="1%">
                <div className="home-solution-col-div1">
                  <Fade bottom distance="1%">
                    <img src={solutionplanimg} className="img-fluid" alt="" />
                  </Fade>
                  <Fade bottom distance="1%">
                    <h2>Starting from</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <h1>£ 9.00</h1>
                  </Fade>
                  <div>
                    <Fade bottom distance="1%">
                      <HashLink
                        to="#contact-form"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                      >
                        <div className="home-solution-btn1">Get a Quote</div>
                      </HashLink>
                    </Fade>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>


      {/* // ########### Section No 3 ############ */}
      <div className="container-fluid home-sec3-card-div11 ">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <Fade bottom distance="1%">
                      <img src={cardimg1} alt="" />
                    </Fade>
                  </div>
                  <Fade bottom distance="1%">
                    <h2>50+</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p>Countries Served</p>
                  </Fade>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <Fade bottom distance="1%">
                      <img src={cardimg2} alt="" />
                    </Fade>
                  </div>
                  <Fade bottom distance="1%">
                    <h2>100K</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p>Delivery Made</p>
                  </Fade>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <Fade bottom distance="1%">
                      <img src={cardimg3} alt="" />
                    </Fade>
                  </div>
                  <Fade bottom distance="1%">
                    <h2>99%</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p>Satisfaction Rate</p>
                  </Fade>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-6">
              <Fade bottom distance="1%">
                <div className="home-sec3-card-div22">
                  <div>
                    <Fade bottom distance="1%">
                      <img src={cardimg4} alt="" />
                    </Fade>
                  </div>
                  <Fade bottom distance="1%">
                    <h2>24/7</h2>
                  </Fade>
                  <Fade bottom distance="1%">
                    <p>Support Hours</p>
                  </Fade>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>



      {/* // ########### Section No 4 ############ */}
      <div className="container-fluid home-idea-div1">
        <div className="container">
          <div className="home-idea-text-div1">
            <div className="home-idea-text-div2">
              <Fade bottom distance="1%">
                <h2>An idea of what </h2>
              </Fade>
              <Fade bottom distance="1%">
                <h3 className='d-flex align-items-center'>you can send ! <img src={ideabox} className="d-md-block d-none" alt="" /></h3>
              </Fade>
            </div>
            <img src={ideabox} className="d-md-none d-block" width='90' alt="" />
            <div className="d-md-block d-none">
              <Fade bottom distance="1%">
                <HashLink
                  to="#contact-form"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={600}
                >
                  <div className="home-idea-btn1">Get a Quote</div>
                </HashLink>
              </Fade>
            </div>
          </div>

          <div className="row home-idea-row-div11">
            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg1} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">Shoes</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg2} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">Clothes</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg3} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">Toys</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg4} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">dry fruits</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg5} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">LCD</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4  d-md-block d-none">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg6} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">Washing machine</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg7} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">AC</div>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-lg-3 col-4 d-md-block d-none">
              <Fade bottom distance="1%">
                <div>
                  <div className="home-idea-img-div11">
                    <img src={itemimg8} alt="" className="img-fluid" />
                  </div>
                  <div>
                    <div className="home-idea-btn-div11">Books</div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <Fade bottom distance="1%">
            <p className='home-much-more d-md-none d-block'>+ Much More</p>
          </Fade>
        </div>
      </div>


      <Section></Section>


    </div>
  );
}

export default Home;
